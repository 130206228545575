import { assign, DoneInvokeEvent, createMachine } from 'xstate';
import { VerifyAddressResponse } from '../../../../Models/jena/response/VerifyAddressResponse';
import IAddress, { Address } from '../../../../Models/Address';
import { TVerifyAddressesEvents } from './VerifyAddressesEvents';
import contactReducer, {
  ContactAction,
} from '../../../../reducers/contactReducer';

export const shippingAddressValidators = {
  addressHasStreet: (address?: IAddress) => !!address && !!address.street,
  addressHasStreetNumber: (address?: IAddress) =>
    !!address && !!address.streetNumber,
  address: (address?: IAddress) =>
    !!address &&
    shippingAddressValidators.addressHasStreet(address) &&
    shippingAddressValidators.addressHasStreetNumber(address),
};

export interface ShippingAddressesContext {
  shipper: Address;
  consignee: Address;
  verified: boolean;
}

const addressStates = {
  initial: 'initial',
  states: {
    initial: {},
    valid: {
      always: {
        cond: 'needsValidation',
        target: '#verify',
      },
    },
    error: {
      entry: 'updateVerified',
      states: {
        missingStreet: {},
        missingStreetNumber: {},
        verificationError: {},
      },
    },
  },
};

export const verifyAddressesMachine = createMachine<
  ShippingAddressesContext,
  TVerifyAddressesEvents
>(
  {
    id: 'verifyAddressesMachine',
    initial: 'initial',
    states: {
      initial: {},
      editing: {
        id: 'editing',
        type: 'parallel',
        // entry: 'updateVerified',
        states: {
          shipper: addressStates,
          consignee: addressStates,
        },
      },
      verify: {
        id: 'verify',
        // entry: ['updateVerified'],
        invoke: {
          id: 'verify',
          src: 'verify',
          onDone: [
            {
              cond: 'hasShipperError',
              target: '#editing.shipper.error.verificationError',
            },
            {
              cond: 'hasConsigneeError',
              target: '#editing.consignee.error.verificationError',
            },
            {
              cond: 'hasVerificationError',
              target: 'error',
            },
            {
              target: 'verified',
              actions: assign(
                (ctx, event: DoneInvokeEvent<VerifyAddressResponse>) => {
                  return {
                    shipper: ctx.shipper && {
                      ...ctx.shipper,
                      countryId: event.data?.fromcountryid,
                      cityId: event.data?.fromcityid,
                      postCode: event.data?.frompostcode,
                    },
                    consignee: ctx.consignee && {
                      ...ctx.consignee,
                      countryId: event.data?.tocountryid,
                      cityId: event.data?.tocityid,
                      postCode: event.data?.topostcode,
                    },
                    verified: true,
                  };
                }
              ),
            },
            {
              target: 'error',
            },
          ],
          onError: 'error',
        },
      },
      error: {
        entry: 'updateVerified',
      },
      verified: {
        entry: 'updateVerified',
        // always: {
        //   // actions: 'updateVerified',
        // },
      },
    },
    on: {
      SELECT_SHIPPER: [
        {
          target: 'editing.shipper',
          cond: 'emptyAddress',
          actions: [
            assign({
              shipper: (ctx, event) => ({} as Address),
              verified: (_) => false,
            }),
            'clearShipper',
          ],
        },
        {
          target: 'editing.shipper.error.missingStreet',
          cond: 'isMissingStreet',
          actions: assign({
            shipper: (ctx, event) => ({
              ...ctx.shipper,
              ...(event.data as unknown as Address),
            }),
            verified: (_) => false,
          }),
        },
        {
          target: 'editing.shipper.error.missingStreetNumber',
          cond: 'isMissingStreetNumber',
          actions: assign({
            shipper: (ctx, event) => ({
              ...ctx.shipper,
              ...(event.data as unknown as Address),
            }),
            verified: (_) => false,
          }),
        },
        {
          actions: assign({
            shipper: (ctx, event) => ({
              ...ctx.shipper,
              ...(event.data as unknown as Address),
            }),
            verified: (_) => false,
          }),
          target: 'editing.shipper.valid',
        },
      ],
      SELECT_CONSIGNEE: [
        {
          target: 'editing.consignee',
          cond: 'emptyAddress',
          actions: [
            assign({
              consignee: (ctx, event) => ({} as Address),
              verified: (_) => false,
            }),
            'clearConsignee',
          ],
        },
        {
          target: 'editing.consignee.error.missingStreet',
          cond: 'isMissingStreet',
          actions: assign({
            consignee: (ctx, event) => ({
              ...ctx.consignee,
              ...(event.data as unknown as Address),
            }),
            verified: (_) => false,
          }),
        },
        {
          target: 'editing.consignee.error.missingStreetNumber',
          cond: 'isMissingStreetNumber',
          actions: assign({
            consignee: (ctx, event) => ({
              ...ctx.consignee,
              ...(event.data as unknown as Address),
            }),
            verified: (_) => false,
          }),
        },
        {
          actions: assign({
            consignee: (ctx, event) => ({
              ...ctx.consignee,
              ...(event.data as unknown as Address),
            }),
            verified: (_) => false,
          }),
          target: 'editing.consignee.valid',
        },
      ],
      UPDATE_SENDER: {
        target: 'editing.shipper',
        actions: [
          assign({
            shipper: (ctx, event) =>
              contactReducer(
                ctx.shipper,
                event.data as unknown as ContactAction
              ),
          }),
          'updateAddresses',
        ],
      },
      UPDATE_CONSIGNEE: {
        target: 'editing.consignee',
        actions: [
          assign({
            consignee: (ctx, event) =>
              contactReducer(
                ctx.consignee,
                event.data as unknown as ContactAction
              ),
          }),
          'updateAddresses',
        ],
      },
    },
  },
  {
    guards: {
      emptyAddress: (_, event) => {
        return !event.data;
      },
      needsValidation: (ctx) =>
        shippingAddressValidators.address(ctx.consignee) &&
        shippingAddressValidators.address(ctx.shipper) &&
        !ctx.verified,
      isMissingStreet: (_, event) =>
        !shippingAddressValidators.addressHasStreet(event.data as Address),
      isMissingStreetNumber: (_, event) =>
        !shippingAddressValidators.addressHasStreetNumber(
          event.data as Address
        ),
      hasConsigneeError: (ctx, event) => {
        const data = event.data as VerifyAddressResponse;
        return !!data.error_text && /Consignee.*/.test(data.error_text);
      },
      hasShipperError: (ctx, event) => {
        const data = event.data as VerifyAddressResponse;
        return !!data.error_text && /Shipper.*/.test(data.error_text);
      },
      hasVerificationError: (ctx, event) => {
        const data = event.data as VerifyAddressResponse;
        return !!data.error_text;
      },
    },
  }
);
