export interface ListConsignmentResponse {
  listconsignment: ('consignment' | ConsignmentResponse)[];
  custorgunitid: string;
  custorgid: string;
  numrows?: string;
  sortorder: string;
  result: string;
}
export interface ConsignmentResponse {
  consignmentid: string;
  awbnbr: string;
  packageqty: string;
  contents: string;
  shippercity: string;
  consigneecity: string;
  weightstr: string;
  weight: string;
  deliverydate: string;
  deliverytime: string;
  localpickupopentime?: string;
  reporteddate?: string;
  reportedtime?: string;
  localdeliverydatetime: string;
  status?: ConsignmentStatus;
  shipper: string;
  consignee: string;
  date: Date;
  trackinglink?: string;
}
export enum ConsignmentStatus {
  Created = '1',
  Booked = '2',
  Cancelled = '3',
  Delivered = '4',
}

export interface OrderHistoryRows {
  numRows: number;
  consignments: ConsignmentResponse[];
}
