/** @jsxImportSource @emotion/react */
import { useContext, Fragment, useEffect } from 'react';
import ConfigContext from './context/ConfigContext';
import {
  Switch,
  Route,
  useRouteMatch,
  useHistory,
  useLocation,
  useParams,
} from 'react-router-dom';
import tw from 'twin.macro';
import TabNavigation, { Pages, Tab } from './Components/TabNavigation';
import sendPackageMachine from './Machines/sendPackageMachine';
import { StateFrom } from 'xstate';
import { SendPackageEvents } from './events/SendPackageEvents';
import {
  DeliveryPage,
  PackageInformationPage,
  CustomsPage,
  PaymentPage,
  UserPage,
  RegisterPage,
} from './Pages';
import Header from './Components/Header';
import useTranslation from './hooks/useTranslation';
import { ModalLarge, ModalSmall } from './Components/Modal/Modal';
import { OrderConfirmationPage } from './Pages/OrderConfirmation/OrderConfirmationPage';
import { PageTransition } from './Components/PageTransition';
import { ToastContainer } from 'react-toastify';
import Loader, { LoaderLabel } from './Components/Base/Loader';
import { useSendPackageMachine } from './hooks/useSendPackageMachine';
import { deliveryMachineGuards } from './Pages/Delivery/deliveryMachine';
import { packageInformationGuards } from './Pages/PackageInformation/packageInformationMachine';
import { PaymentGuards } from './Pages/Payment/paymentPageMachine';
import { customsGuards } from './Pages/Customs/CustomsMachine';
import ResetPasswordPage from './Pages/ResetPassword/ResetPasswordPage';
import ChangePasswordPage from './Pages/ResetPassword/ChangePassword';
import OrderHistory from './Components/OrderHistory/OrderHistory';
import virtualPageview from './virtualPageview';
import { countryToLanguage } from './mappers/jenaMappers';

const tabValid = (tab: Pages) => {
  switch (tab) {
    case Pages.delivery:
      return deliveryMachineGuards.deliveryStepComplete;
    case Pages.package:
      return packageInformationGuards.validPackageInformationPage;
    case Pages.payment:
      return PaymentGuards.validPayment;
    case Pages.customs:
      return customsGuards.validCustoms;
  }
};

function App() {
  const { appMode, setLanguage } = useContext(ConfigContext);
  const { country } = useParams<{ country: string }>();
  let { path, isExact } = useRouteMatch();

  useEffect(() => {
    setLanguage(countryToLanguage(country));
  }, [country, setLanguage]);

  const translations = useTranslation();
  const [state, send] = useSendPackageMachine();
  const { pathname } = useLocation();
  const history = useHistory();

  const tabs = [
    { value: Pages.delivery, label: translations.deliveryPage.tab },
    {
      value: Pages.package,
      label: translations.packageInformationPage.tab,
    },
    // {
    //   value: Pages.address,
    //   label: translations.senderAndConsigneePage.tab,
    // },
    state.context.customsRequired && {
      value: Pages.customs,
      label: translations.customsPage.tab,
    },
    { value: Pages.payment, label: translations.paymentPage.tab },
  ].filter((t): t is Pick<Tab, 'value' | 'label'> => t !== false);

  const getTabStatus = (
    state: StateFrom<typeof sendPackageMachine>,
    tab: Pages
  ) => {
    if (state.matches(tab)) return 'active';
    // @ts-ignore
    if (tabValid(tab)(state.context)) return 'done';
    return 'inactive';
  };
  const goBack = () => history.push(`${country ? `/${country}` : '/'}`);

  const bookingHidden = !isExact;
  useEffect(() => virtualPageview(pathname, pathname), [pathname]);
  useEffect(() => {
    document.title = translations.meta.title;
  }, [translations]);

  return (
    <Fragment>
      {appMode === 'standalone' ? <Header /> : null}

      <Switch>
        <Route path={`${path}/login`}>
          <ModalSmall
            isOpen={true}
            onRequestClose={goBack}
            handleClose={goBack}
          >
            <UserPage />
          </ModalSmall>
        </Route>
        <Route path={`${path}/register`}>
          <ModalLarge
            isOpen={true}
            onRequestClose={goBack}
            handleClose={goBack}
          >
            <RegisterPage />
          </ModalLarge>
        </Route>
        <Route path={`${path}/reset-password/:token`}>
          <ModalSmall
            isOpen={true}
            onRequestClose={goBack}
            handleClose={goBack}
          >
            <ResetPasswordPage />
          </ModalSmall>
        </Route>
        <Route path={`${path}/reset-password`}>
          <ModalSmall
            isOpen={true}
            onRequestClose={goBack}
            handleClose={goBack}
          >
            <ResetPasswordPage />
          </ModalSmall>
        </Route>
        <Route path={`${path}/change-password`}>
          <ModalSmall
            isOpen={true}
            onRequestClose={goBack}
            handleClose={goBack}
          >
            <ChangePasswordPage />
          </ModalSmall>
        </Route>
        <Route path={`${path}/order-history`}>
          <ModalLarge
            isOpen={true}
            onRequestClose={goBack}
            handleClose={goBack}
          >
            <OrderHistory state={state.context} send={send} onClose={goBack} />
          </ModalLarge>
        </Route>
      </Switch>

      {state.matches('payment.processing') && (
        <Loader variant="processing-order">
          <LoaderLabel label={translations.paymentProcessing} />
        </Loader>
      )}

      <h1 tw="sr-only">{translations.meta.appName}</h1>

      <div
        aria-hidden={bookingHidden}
        css={[bookingHidden && tw`hidden`, tw`flex-grow flex flex-col`]}
      >
        {!state.matches('packageTypes') && (
          <TabNavigation
            tabs={tabs.map((tab, idx) => ({
              ...tab,
              idx: ++idx,
              state: getTabStatus(state, tab.value),
            }))}
            onChange={(val: Pages) => {
              if (state.matches('orderConfirmation'))
                send(SendPackageEvents.reset());
              else send(SendPackageEvents[val]());
            }}
          />
        )}

        <div tw="bg-gray-100 h-full flex flex-grow flex-col">
          <PageTransition
            in={state.matches('packageTypes')}
            unmountOnExit={true}
          >
            <div tw={'flex justify-center m-12'}>
              <div>
                {state.matches('packageTypes.loading') && (
                  <p>{translations.loading}</p>
                )}
                {state.matches('packageTypes.error') && (
                  <p>{translations.packageTypes.error}</p>
                )}
              </div>
            </div>
          </PageTransition>

          <PageTransition in={state.matches('delivery')} unmountOnExit={true}>
            <DeliveryPage
              state={state.context}
              send={send}
              matches={(key) => state.matches({ delivery: key })}
            />
          </PageTransition>

          <PageTransition in={state.matches('package')}>
            <PackageInformationPage
              send={send}
              state={state.context}
              matches={(key) => state.matches({ package: key })}
            />
          </PageTransition>

          <PageTransition in={state.matches('customs')}>
            <CustomsPage
              send={send}
              state={state.context}
              matches={(key) => state.matches({ customs: key })}
            />
          </PageTransition>

          <PageTransition in={state.matches('payment')}>
            <PaymentPage
              send={send}
              state={state.context}
              matches={(key) => state.matches({ payment: key })}
            />
          </PageTransition>

          <PageTransition in={state.matches('orderConfirmation')}>
            <OrderConfirmationPage
              context={state.context}
              send={send}
              matches={(key) => state.matches({ payment: key })}
              reset={() => send(SendPackageEvents.reset())}
            />
          </PageTransition>
        </div>
      </div>
      <ToastContainer />
    </Fragment>
  );
}

export default App;
