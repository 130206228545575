/** @jsxImportSource @emotion/react */
import { RefObject, useEffect, useRef, useContext } from 'react';
import { Suggestion } from 'use-places-autocomplete';
import useClickOutsideHandler from '../../hooks/useClickOutsideHandler';
import tw, { css } from 'twin.macro';
import { useMachine } from '@xstate/react/lib';
import { addressAutocompleteMachine } from './addressAutocompleteMachine';
import { AddressAutocompleteEvents } from './AddressAutocompleteEvents';
import useHotkeys from '../../hooks/useHotkeys';
import hotkeys from 'hotkeys-js';
import IAddress from '../../Models/Address';
import useAddressAutocomplete from './useAddressAutocomplete';
import ButtonClear from '../Base/ButtonClear';
import { formatAddress } from '../../helpers/addressFormatter';
import { useBoundEvents } from '../../hooks/useBoundEvents';
import { UserContext } from '../../context/UserContext';
import { IconSearch } from '../../svg';

export interface AddressAutocompleteProps {
  id: string;
  onSelect: (prediction?: Suggestion) => void;
  address?: IAddress;
  placeholder: string;
  ariaLabelClear: string;
  error?: boolean;
  inputRef: RefObject<HTMLInputElement>;
  // defaultValue?: string | null;
  onClear: () => void;
}

const logo = css`
  padding: 0.75rem 1.15rem;
  text-align: right;
`;

const AddressAutocomplete = ({
  id,
  onSelect,
  address,
  placeholder,
  ariaLabelClear,
  error,
  inputRef,
  onClear,
}: AddressAutocompleteProps) => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const defaultValue: string | undefined = urlSearchParams.has(id)
    ? urlSearchParams.get(id)?.toString()
    : undefined;

  const { ready, search, suggestions } = useAddressAutocomplete(id);
  const [state, send] = useMachine(addressAutocompleteMachine, {
    actions: {
      load: (ctx) => {
        search(ctx.value);
      },
      onSelect: (ctx) => {
        onSelect(ctx.selection);
      },
      prefill: (ctx) => {
        if (
          typeof ctx.highlight === 'number' &&
          ctx.suggestions[ctx.highlight] &&
          inputRef?.current
        ) {
          const input = inputRef.current;
          const { main_text, main_text_matched_substrings } =
            ctx.suggestions[ctx.highlight].structured_formatting;
          const match = main_text_matched_substrings.find(
            (m: any) =>
              m.length >= ctx.value.length /*input.value.length */ &&
              m.offset === 0
          );
          if (match) {
            input.value = main_text;
            input.setSelectionRange(match.length, main_text.length);
          }
        }
      },
    },
    context: {
      value: formatAddress(address) || defaultValue || '',
      suggestions: [],
    },
  });

  const events = useBoundEvents(AddressAutocompleteEvents, send);
  const { isLoggedIn } = useContext(UserContext);
  useEffect(() => {
    if (inputRef?.current) {
      inputRef.current.value = formatAddress(address) || '';
    }
  }, [address, inputRef, defaultValue]);
  useEffect(() => {
    events.receivedSuggestions(suggestions);
  }, [events, suggestions]);

  useEffect(() => {
    if (ready) {
      events.ready();
    }
  }, [events, ready]);

  useHotkeys(
    'up,down,esc,enter',
    (_, handler) => {
      _.preventDefault();
      switch (handler.key) {
        case 'up':
          events.moveUp();
          break;
        case 'down':
          events.moveDown();
          break;
        case 'esc':
          events.onSelect(undefined);
          handleClearInput();
          break;
        case 'enter':
          if (typeof state.context.highlight === 'number')
            events.onSelect(state.context.suggestions[state.context.highlight]);
      }
    },
    [send, state.context.suggestions, state.context.highlight],
    { scope: id, filter: () => true }
  );

  // handle hotkey scope depending on current state
  useEffect(() => {
    if (state.matches('selecting')) hotkeys.setScope(id);
    else hotkeys.setScope('all');
  }, [id, state, state.value]);

  const ref = useRef<HTMLDivElement>(null);
  useClickOutsideHandler(ref, () =>
    send(AddressAutocompleteEvents.onSelect(undefined))
  );

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    // Update the keyword of the input element
    const val = e.target.value;
    events.onInput(val);
  };

  const handleClearInput = () => {
    if (inputRef.current) {
      inputRef.current.value = '';
      inputRef.current?.focus();
    }

    // onSelect();
    // send(AddressAutocompleteEvents.onSelect(undefined));
    onClear();
  };

  const renderSuggestions = () =>
    state.context.suggestions.map((suggestion, index) => {
      const {
        place_id: predictionId,
        structured_formatting: { main_text, secondary_text },
        place_id,
      } = suggestion;

      return (
        <li
          key={predictionId || place_id}
          css={[
            tw`px-4 py-3 border-b hover:bg-gray-300 cursor-pointer text-base`,
            index === state.context.highlight ? tw`bg-gray-300` : '',
          ]}
          id={`${id}-list-${index}`}
          onClick={() => events.onSelect(suggestion)}
          role="option"
          aria-selected={index === state.context.highlight}
        >
          <strong tw={'block leading-none'}>{main_text}</strong>{' '}
          <small>{secondary_text}</small>
        </li>
      );
    });

  const errorclass = error && tw`border-red! border-2!`;
  const showResults = state.matches('selecting') || state.matches('loading');
  return (
    <div>
      <div
        ref={ref}
        role="combobox"
        aria-owns={`${id}-list`}
        aria-haspopup="listbox"
        aria-controls={`${id}-list`}
        aria-expanded={showResults}
        tw="relative flex items-center"
      >
        <input
          ref={inputRef}
          css={[
            tw`max-w-full w-full py-1 pl-2 pr-8 rounded bg-white border-gray-500 border text-base leading-loose`,
            isLoggedIn &&
              css`
                padding-left: 3rem;
              `,
            errorclass,
          ]}
          id={id}
          defaultValue={state.context.value}
          onChange={handleInput}
          disabled={state.matches('initializing')}
          placeholder={placeholder}
          type="text"
          autoComplete="off"
          aria-autocomplete="list"
          aria-controls={`${id}-list`}
          aria-activedescendant={
            state.context.highlight !== undefined
              ? `${id}-list-${state.context.highlight}`
              : undefined
          }
        />
        {showResults && (
          <ul
            id={`${id}-list`}
            role="listbox"
            css={[
              css`
                top: calc(100% + 1px);
              `,
              tw`absolute z-10 w-full border border-gray-200 bg-white rounded shadow-lg overflow-hidden`,
            ]}
          >
            {renderSuggestions()}
            {/* Gotta please the google ~gods~ terms :) */}
            <li css={logo}>
              <img
                src="https://developers.google.com/static/maps/documentation/images/google_on_white.png"
                alt="Powered by Google"
                tw={'w-10'}
                loading="lazy"
              />
            </li>
          </ul>
        )}
        {inputRef?.current?.value ? (
          <ButtonClear label={ariaLabelClear} handleClick={handleClearInput} />
        ) : (
          <div tw="absolute right-0 h-full px-3 flex justify-center items-center">
            <IconSearch
              css={[
                tw`w-full`,
                css`
                  width: 1.25rem;
                `,
              ]}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default AddressAutocomplete;
