import { assign, MachineConfig } from 'xstate';
import contactReducer from '../../reducers/contactReducer';
import { TSenderAndConsigneeEvents } from './SenderAndConsigneeEvents';
import { Address } from '../../Models/Address';
import { TGuards } from '../../Machines/sendPackageMachine';
import virtualPageview from '../../virtualPageview';

export interface SenderAndConsigneeContext {
  shipper: Address;
  consignee: Address;
  customsRequired: boolean;
}

export enum SenderConsigneeGuards {
  validShipperContact = 'validShipperContact',
  validConsigneeContact = 'validConsigneeContact',
  validSenderAndConsignee = 'validSenderAndConsignee',
}

export const senderAndConsigneeMachine: MachineConfig<
  SenderAndConsigneeContext,
  any,
  TSenderAndConsigneeEvents
> = {
  id: 'senderAndConsignee',
  initial: 'init',
  states: {
    init: {
      entry: [
        () => virtualPageview('/sender-recipient', 'Sender and recipient'),
      ],
      always: {
        target: 'editing',
        actions: 'scrollToTop',
      },
    },
    editing: {
      always: {
        cond: SenderConsigneeGuards.validSenderAndConsignee,
        target: 'valid',
      },
    },
    valid: {
      on: {
        SENDER_CONSIGNEE_DONE: [
          {
            cond: (ctx) => ctx.customsRequired,
            target: '#customs',
          },
          {
            target: '#payment',
          },
        ],
      },
    },
  },
  on: {
    SENDER_CONSIGNEE_DONE: {
      target: '#senderAndConsignee.editing',
      actions: assign({
        shipper: (ctx) => ({
          ...ctx.shipper,
          name: ctx.shipper.name || '', // override to empty string to trigger validation errors ;v
          phone: ctx.shipper.phone || '',
        }),
        consignee: (ctx) => ({
          ...ctx.consignee,
          name: ctx.consignee.name || '', // override to empty string to trigger validation errors ;v
          phone: ctx.consignee.phone || '',
        }),
      }),
    },
    UPDATE_SENDER: {
      target: '#senderAndConsignee.editing',
      actions: assign({
        shipper: (ctx, event) => contactReducer(ctx.shipper, event.data),
      }),
    },
    UPDATE_CONSIGNEE: {
      target: '#senderAndConsignee.editing',
      actions: assign({
        consignee: (ctx, event) => contactReducer(ctx.consignee, event.data),
      }),
    },
  },
};
const minLength = (val: string, len: number = 2) => !!val && val.length >= len;

export const validContact = (contact: Address) =>
  minLength(contact.firstName || '') &&
  minLength(contact.lastName || '') &&
  minLength(contact.phone || '', 6);

export const senderAndConsigneeGuards: TGuards<SenderConsigneeGuards> = {
  validShipperContact: (ctx) => validContact(ctx.shipper),
  validConsigneeContact: (ctx) => validContact(ctx.consignee),
  validSenderAndConsignee: (ctx) =>
    validContact(ctx.shipper) && validContact(ctx.consignee),
};
