import {
  createDataEvent,
  createEvent,
  EventActionType,
} from '../../helpers/eventCreator';
import DeliveryOption from '../../Models/DeliveryOption';
import { PackageAction } from './packageReducer';
import RequestTypes from '../../enums/RequestTypes';
import { GeocodeDoneInvokeEvent } from '../../Components/AddressAutocomplete/addressAutocompleteMachine';
import { ShippingAddressesContext } from './Components/ShippingAddresses/verifyAddressesMachine';
import { PartnerInformation } from '../../Models/PartnerInformation';
import { ChangeEvent } from 'react';
import { ShowBookingInfoResponse } from '../../Models/jena/response/ShowBookingInfoResponse';

export const DeliveryEvents = {
  changePackage: (action: PackageAction) =>
    createDataEvent('CHANGE_PACKAGES', action),

  clearPackages: () => createEvent('CLEAR_PACKAGES'),

  addressVerified: (ctx: ShippingAddressesContext) =>
    createDataEvent('ADDRESS_VERIFIED', ctx),

  setPartnerInfo: (partnerInfo: PartnerInformation) =>
    createDataEvent('SET_PARTNERINFO', partnerInfo),

  selectOption: (deliveryOption: DeliveryOption) =>
    createDataEvent('SELECT_OPTION', deliveryOption),

  selectRequestType: (requestType: string) =>
    createDataEvent(
      'SELECT_REQUESTTYPE',
      RequestTypes[requestType as keyof typeof RequestTypes]
    ),

  selectDate: (date: string) => createDataEvent('SELECT_DATE', date),
  selectTime: (e: ChangeEvent<HTMLInputElement>) =>
    createDataEvent('SELECT_TIME', e.target.value),

  selectDateStart: (date: string) => createDataEvent('SELECT_DATE_START', date),
  selectDateEnd: (date: string) => createDataEvent('SELECT_DATE_END', date),

  selectStartTimeOpen: (e: ChangeEvent<HTMLInputElement>) =>
    createDataEvent('SELECT_TIME_START_OPEN', e.target.value),
  selectStartTimeClose: (e: ChangeEvent<HTMLInputElement>) =>
    createDataEvent('SELECT_TIME_START_CLOSE', e.target.value),
  selectEndTimeOpen: (e: ChangeEvent<HTMLInputElement>) =>
    createDataEvent('SELECT_TIME_END_OPEN', e.target.value),
  selectEndTimeClose: (e: ChangeEvent<HTMLInputElement>) =>
    createDataEvent('SELECT_TIME_END_CLOSE', e.target.value),

  blurTime: () => createEvent('BLUR_TIME'),

  loadAlternatives: () => createEvent('LOAD_ALTERNATIVES'),

  done: () => createEvent('DELIVERY_DONE'),

  setLoggedIn: () => createEvent('SET_LOGGED_IN'),

  createSupportTicket: () => createEvent('CREATE_SUPPORT_TICKET'),

  updateAddresses: (ctx: ShippingAddressesContext) =>
    createDataEvent('UPDATE_ADDRESSES', ctx),

  clearShipper: () => createEvent('CLEAR_SHIPPER'),
  clearConsignee: () => createEvent('CLEAR_CONSIGNEE'),
  copyBooking: (booking: ShowBookingInfoResponse) =>
    createDataEvent('COPY_BOOKING', booking),
};

export type TDeliveryEvents =
  | EventActionType<typeof DeliveryEvents>
  | GeocodeDoneInvokeEvent;
