/** @jsxImportSource @emotion/react */
import tw from 'twin.macro';
import { useContext, useRef, Fragment } from 'react';
import GeocodedAutocomplete from './GeocodedAutocomplete';
import { useMachine } from '@xstate/react/lib';
import { Address } from '../../../../Models/Address';
import useTranslation from '../../../../hooks/useTranslation';
import {
  FormInput,
  FormLabel,
  FormTextarea,
  PhoneField,
} from '../../../../Components/FormFields';
import { VerifyAddressesEvents } from './VerifyAddressesEvents';
import FormMessage from '../../../../Components/Base/FormMessage';
import {
  ShippingAddressesContext,
  verifyAddressesMachine,
} from './verifyAddressesMachine';
import { verifyAddressRequest } from '../../../../mappers/jenaMappers';
import useJenaApi from '../../../../hooks/useJenaApi';
import { UserContext } from '../../../../context/UserContext';
import { OpenAddressBook } from './OpenAddressBook';
import { useBoundEvents } from '../../../../hooks/useBoundEvents';
import {
  LanguageFlag,
  Translations,
} from '../../../../translations/TranslationType';
import { useHistory } from 'react-router-dom';
import { FormGroup } from '../../../../Components/Base';
import { ContactActions } from '../../../../reducers/contactReducer';

interface ShippingAddressesProps {
  onVerified: (context: ShippingAddressesContext) => void;
  shipperAddress?: Address;
  consigneeAddress?: Address;
  verified: boolean;
  updateAddresses: (context: ShippingAddressesContext) => void;
  clearShipper: () => void;
  clearConsignee: () => void;
}

interface AddressErrorProps {
  which: 'consignee' | 'shipper';
  matches: (value: string) => boolean;
}

const AddressError = ({ which, matches }: AddressErrorProps) => {
  const translations = useTranslation();
  const errorStates: (keyof Translations['addressValidationErrors'])[] = [
    'missingStreet',
    'missingStreetNumber',
    'verificationError',
  ];
  const errorBase = `editing.${which}.error`;
  if (matches(errorBase)) {
    const translationString =
      errorStates.find((err) => matches(`${errorBase}.${err}`)) || 'error';

    return (
      <FormMessage
        label={translations.addressValidationErrors[translationString]}
        status={'error'}
      />
    );
  }
  return null;
};

export const ShippingAddresses = ({
  shipperAddress,
  consigneeAddress,
  verified,
  onVerified,
  updateAddresses,
  clearShipper,
  clearConsignee,
}: ShippingAddressesProps) => {
  const translations = useTranslation();
  const history = useHistory();
  const api = useJenaApi();
  const shipperRef = useRef<HTMLInputElement>(null);
  const consigneeRef = useRef<HTMLInputElement>(null);
  const { user, isLoggedIn, userEvents } = useContext(UserContext);
  const [state, send] = useMachine(verifyAddressesMachine, {
    context: {
      shipper: shipperAddress,
      consignee: consigneeAddress,
      verified: verified,
    },
    actions: {
      updateVerified: onVerified,
      updateAddresses,
      clearShipper,
      clearConsignee,
    },
    services: {
      verify: ({ shipper, consignee }) => {
        const urlSearchParams = new URLSearchParams(window.location.search);
        if (
          urlSearchParams.has('shipper') ||
          urlSearchParams.has('consignee')
        ) {
          history.replace('/');
        }

        return api.verifyAddress(
          verifyAddressRequest(
            shipper || ({} as Address),
            consignee || ({} as Address)
          )
        );
      },
    },
  });
  const events = useBoundEvents(VerifyAddressesEvents, send);
  const { shipper, consignee } = state.context;

  return (
    <Fragment>
      <div css={tw`col-12 md:col-6 xl:col-5 mb-2`}>
        <div css={tw`w-full relative flex flex-col mb-2`}>
          {isLoggedIn && (
            <OpenAddressBook
              label={translations.addressBook}
              onSelect={events.selectShipper}
              addresses={user?.addresses}
              onRemove={userEvents?.removeAddress}
              onAdd={userEvents?.reloadUser}
              numAddress={user?.numAddress}
            />
          )}

          <FormLabel name="shipper">{translations.shipperAddress}</FormLabel>
          <GeocodedAutocomplete
            id={'shipper'}
            placeholder={translations.shipperAddressPlaceholder}
            onSelect={(address) => {
              events.selectShipper(address);
            }}
            address={shipper}
            ariaLabelClear={translations.clear}
            error={state.matches('editing.shipper.error')}
            inputRef={shipperRef}
            onClear={() => {
              events.selectShipper(undefined);
            }}
          />
          <AddressError which={'shipper'} matches={state.matches} />
        </div>

        <FormGroup>
          <FormInput
            label={translations.company}
            value={shipper?.addressName || ''}
            name="company"
            autoComplete="organization"
            onChange={(val) => {
              events.updateSender(ContactActions.changeCompany(val));
            }}
          />
        </FormGroup>

        <div tw="row mb-2">
          <div tw="col-6">
            <FormInput
              label={translations.registerPage.fields.firstName}
              value={shipper?.firstName || ''}
              name="senderFirstName"
              autoComplete="name"
              error={
                shipper.firstName !== undefined && shipper.firstName.length < 2
              }
              errorMessage={translations.requiredField}
              required={true}
              onChange={(val) => {
                events.updateSender(ContactActions.changeFirstName(val));
              }}
            />
          </div>
          <div tw="col-6">
            <FormInput
              label={translations.registerPage.fields.lastName}
              value={shipper?.lastName || ''}
              name="senderLastName"
              autoComplete="name"
              error={
                shipper.lastName !== undefined && shipper.lastName.length < 2
              }
              errorMessage={translations.requiredField}
              required={true}
              onChange={(val) => {
                events.updateSender(ContactActions.changeLastName(val));
              }}
            />
          </div>
        </div>

        <FormGroup>
          <PhoneField
            label={translations.phone}
            // type="tel"
            value={shipper?.phone || ''}
            name="senderPhoneNumber"
            country={
              shipper.countryCode?.toLowerCase() ??
              LanguageFlag[translations.meta.languageCode]
            }
            // autoComplete="tel"
            error={!!shipper.phone && shipper.phone.length < 7}
            required={true}
            errorMessage={translations.requiredField}
            onChange={(val) => {
              events.updateSender(ContactActions.changePhone(val));
            }}
          />
        </FormGroup>

        <FormGroup>
          <FormLabel name="senderMessage">
            {translations.messageToDriver}
          </FormLabel>
          <FormTextarea
            name="senderMessage"
            placeholder={translations.messageToDriverExample}
            value={shipper?.message || ''}
            onChange={(value) =>
              events.updateSender(ContactActions.changeMessage(value))
            }
          />
        </FormGroup>
      </div>
      <div css={tw`col-12 md:col-6 xl:col-5`}>
        <div css={tw`w-full relative flex flex-col mb-2`}>
          {isLoggedIn && (
            <OpenAddressBook
              label={translations.addressBook}
              onSelect={events.selectConsignee}
              addresses={user?.addresses}
              onRemove={userEvents?.removeAddress}
              numAddress={user?.numAddress}
            />
          )}
          <FormLabel name="consignee">
            {translations.consigneeAddress}
          </FormLabel>
          <GeocodedAutocomplete
            id={'consignee'}
            placeholder={translations.consigneeAddressPlaceholder}
            onSelect={events.selectConsignee}
            address={consignee}
            ariaLabelClear={translations.clear}
            error={state.matches('editing.consignee.error')}
            inputRef={consigneeRef}
            onClear={() => {
              events.selectConsignee(undefined);
            }}
          />
          <AddressError which={'consignee'} matches={state.matches} />
        </div>

        <FormGroup>
          <FormInput
            label={translations.company}
            value={consignee?.addressName || ''}
            name="consigneeCompany"
            autoComplete="organization"
            onChange={(val) => {
              events.updateConsignee(ContactActions.changeCompany(val));
            }}
          />
        </FormGroup>

        <div className="row mb-2">
          <div tw="col-6">
            <FormInput
              label={translations.registerPage.fields.firstName}
              value={consignee?.firstName || ''}
              name="consigneeFirstName"
              autoComplete="name"
              error={
                consignee.firstName !== undefined &&
                consignee.firstName.length < 2
              }
              errorMessage={translations.requiredField}
              required={true}
              onChange={(val) => {
                events.updateConsignee(ContactActions.changeFirstName(val));
              }}
            />
          </div>
          <div tw="col-6">
            <FormInput
              label={translations.registerPage.fields.lastName}
              value={consignee?.lastName || ''}
              name="consigneeLastName"
              autoComplete="name"
              error={
                consignee.lastName !== undefined &&
                consignee.lastName.length < 2
              }
              errorMessage={translations.requiredField}
              required={true}
              onChange={(val) => {
                events.updateConsignee(ContactActions.changeLastName(val));
              }}
            />
          </div>
        </div>

        <FormGroup>
          <PhoneField
            label={translations.phone}
            // type="tel"
            value={consignee?.phone || ''}
            error={!!consignee.phone && consignee.phone.length < 7}
            errorMessage={translations.requiredField}
            name="consigneePhoneNumber"
            country={
              consignee.countryCode?.toLowerCase() ??
              LanguageFlag[translations.meta.languageCode]
            }
            required={true}
            onChange={(val) => {
              events.updateConsignee(ContactActions.changePhone(val));
            }}
          />
        </FormGroup>

        <FormGroup>
          <FormLabel name="consigneeMessage">
            {translations.messageToDriver}
          </FormLabel>
          <FormTextarea
            name="consigneeMessage"
            // tw="h-20"
            placeholder={translations.messageToDriverExample}
            value={consignee?.message || ''}
            onChange={(value) =>
              events.updateConsignee(ContactActions.changeMessage(value))
            }
          />
        </FormGroup>
      </div>
    </Fragment>
  );
};

export default ShippingAddresses;
