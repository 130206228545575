/** @jsxImportSource @emotion/react */
import { Fragment } from 'react';
import tw from 'twin.macro';
import { TextButton, TextLink } from '../../Components/Base';

import {
  FormInput,
  FormCheckbox,
  FormTextarea,
  FormLabel,
} from '../../Components/FormFields';
import {
  PackageInformationContext,
  packageInformationGuards,
} from './packageInformationMachine';
import {
  PackageInformationEvents,
  TPackageInformationEvents,
} from './PackageInformationEvents';
import { Translations } from '../../translations/TranslationType';
import useTranslation from '../../hooks/useTranslation';
import {
  Description,
  HowToWrite,
  HowToWriteProps,
} from './Components/HowToWrite';
import { StateValue } from 'xstate';
import { H2 } from '../../Components/Typograhy';
import { useBoundEvents } from '../../hooks/useBoundEvents';
import StepNavigation, {
  StepNavigationNext,
  StepNavigationRow,
  StepNavigationPrevious,
} from '../../Components/Base/StepNavigation';
import { ModalLarge } from '../../Components/Modal/Modal';
import useToggle from '../../hooks/useToggle';
import { getInsurancePrice } from '../../helpers/getInsurancePrice';

interface PackageInformationPageProps {
  state: PackageInformationContext;
  send: (event: TPackageInformationEvents) => void;
  matches: (key: StateValue) => boolean;
}
const PackageInformationPage = ({
  state,
  send,
  matches,
}: PackageInformationPageProps) => {
  const translation: Translations = useTranslation();
  const events = useBoundEvents(PackageInformationEvents, send);
  const [isOpen, toggleOpen] = useToggle();
  const howtoWrite: HowToWriteProps = {
    labels: {
      howToWrite: translation.packageInformationPage.writeLike,
      howNotToWrite: translation.packageInformationPage.dontWrite,
    },
    descriptions: translation.packageInformationPage.howToWrite.map(
      (label) => ({
        correct: label.correct,
        incorrect: label.incorrect,
      })
    ) as Description[],
  };
  const insurancePrice = getInsurancePrice(
    state.insuranceValue,
    state.insurance
  );
  return (
    <Fragment>
      <div tw={'pt-10 pb-16'}>
        <div css={tw`container mx-auto px-4`}>
          <div tw={'row max-w-xl'}>
            <div tw={'col-12'}>
              <H2>{translation.packageInformationPage.title}</H2>
            </div>
          </div>
          <div css={tw`row`}>
            <div css={tw`col-12 md:col-10 lg:col-7`}>
              <p tw={'mb-2'}>{translation.packageInformationPage.preamble} </p>
              <div tw="mb-4">
                <TextButton handleClick={toggleOpen}>
                  {translation.packageInformationPage.seeExamples}
                </TextButton>
              </div>
              <FormLabel name="content" required>
                {translation.packageInformationPage.packageContentLabel}
              </FormLabel>
              <FormTextarea
                name="content"
                value={state.contents}
                onChange={events.changeContents}
                error={matches('editing.contents.error')}
                errorMessage={translation.requiredField}
                required={true}
              />
            </div>
          </div>
          <div tw={'row mt-3'}>
            <div tw={'col-12 md:col-10 lg:col-7'}>
              <FormCheckbox
                id="read_insurance"
                name="read_insurance"
                text={translation.termsAndPolicies.dangerousGoods.label}
                checked={state.acceptedAirlineTerms}
                onChange={events.changeAcceptedTerms}
                error={matches('editing.acceptTerms.error')}
                errorMessage={translation.requiredField}
                required={true}
              >
                <TextLink
                  href={translation.termsAndPolicies.dangerousGoods.url}
                  target="_blank"
                  rel="noreferrer"
                >
                  {translation.termsAndPolicies.dangerousGoods.urlLabel}.
                </TextLink>
              </FormCheckbox>
            </div>
          </div>

          <ModalLarge
            isOpen={isOpen}
            onRequestClose={toggleOpen}
            handleClose={toggleOpen}
          >
            <Fragment>
              <h3
                css={tw`font-heading font-semibold text-2xl mb-4 leading-tight`}
              >
                {translation.packageInformationPage.titleContentExample}
              </h3>

              <HowToWrite {...howtoWrite} />
            </Fragment>
          </ModalLarge>
        </div>
      </div>
      <hr css={tw`border-t border-gray-200`} />
      <div css={tw`container mx-auto px-4 py-10`}>
        <div tw={'row'}>
          <div tw={'col-12 md:col-10 lg:col-7'}>
            <H2>{translation.insurance.title}</H2>
            <p tw={'mb-4'}>{translation.insurance.description}</p>

            <div tw={'flex items-center mb-4'}>
              <FormCheckbox
                id="use-insurance"
                name="useInsurance"
                text={translation.insurance.labelToggle}
                checked={state.useInsurance}
                onChange={events.changeUseInsurance}
              />
            </div>

            {state.useInsurance && (
              <Fragment>
                <div tw={'w-48'}>
                  <FormInput
                    label={translation.insurance.labelInput}
                    type="number"
                    // min={0}
                    max={state.insurance?.maxValue || 0}
                    value={state.insuranceValue || ''}
                    name="insurance-label"
                    onChange={events.changeInsuranceValue}
                    suffix={state.insurance?.currency || ''}
                    error={matches('editing.value.error')}
                    errorMessage={translation.requiredField}
                    required={state.useInsurance}
                  />
                </div>
                <div tw={'mt-3'}>
                  {translation.insurance.fee}:{' '}
                  <span tw={'inline-block mr-3 text-xl font-bold'}>
                    {insurancePrice.priceincvat} {state.insurance?.currency}
                  </span>
                </div>

                {state.insurance && (
                  <p
                    css={[
                      { fontSize: 'normal', fontStyle: 'italic' },
                      tw`my-3`,
                    ]}
                  >
                    {translation.insurance.insurancePremium(
                      state.insurance.rate,
                      state.insurance.minPrice,
                      state.insurance.currency
                    )}
                  </p>
                )}
              </Fragment>
            )}
          </div>
        </div>
      </div>
      <StepNavigation>
        <StepNavigationRow>
          <StepNavigationPrevious
            label={translation.packageInformationPage.previousStep}
            handleClick={events.delivery}
          />
          <span tw="ml-auto"></span>
          <StepNavigationNext
            // label={translation.packageInformationPage.nextStep}
            label={
              !state.customsRequired
                ? translation.senderAndConsigneePage.nextStep
                : translation.senderAndConsigneePage.nextStepCustoms
            }
            disabled={
              // @ts-ignore
              !packageInformationGuards.validPackageInformationPage(state)
            }
            handleClick={events.donePackageInfo}
          />
        </StepNavigationRow>
      </StepNavigation>
    </Fragment>
  );
};

export default PackageInformationPage;
