/** @jsxImportSource @emotion/react */
import { useContext, Fragment } from 'react';
import tw from 'twin.macro';
import { SendPackageContext } from '../../../Machines/sendPackageMachine';
import { PaymentGuards } from '../paymentPageMachine';
import { PaymentEvents } from '../PaymentEvents';
import { langToLocale } from '../../../mappers/jenaMappers';
import ConfigContext from '../../../context/ConfigContext';
import { StateValue } from 'xstate';
import Modal, {
  modalContentPayment,
  modalOverlayLarge,
  ModalSmall,
} from '../../../Components/Modal/Modal';
import StepNavigation, {
  StepNavigationNext,
  StepNavigationRow,
  StepNavigationPrevious,
} from '../../../Components/Base/StepNavigation';
import useTranslation from '../../../hooks/useTranslation';
import { TextLink } from '../../../Components/Base';
import { NetsCheckout } from './NetsCheckout';

interface CardPaymentProps {
  state: SendPackageContext;
  events: typeof PaymentEvents;
  matches: (key: StateValue) => boolean;
}

const getOneNumber = (value: string | undefined) =>
  value?.split('/')?.slice(-1) || '';

export const CardPayment = ({ events, state, matches }: CardPaymentProps) => {
  const { language } = useContext(ConfigContext);
  const translations = useTranslation();
  const { price, deliveryOption } = state;

  // useMessageListener(getOrigin(), (event) => {
  //   const { status, message, type } = event.data as {
  //     status: string;
  //     message?: string;
  //     type: string;
  //   };

  //   if (type !== 'dibs-reply') return;
  //   switch (status) {
  //     case 'A':
  //       events.cardAccepted(message as string);
  //       break;
  //     case 'D':
  //       events.cardDeclined(message);
  //       break;
  //     default:
  //       events.cardCancelled();
  //   }
  // });

  const error = () => {
    switch (true) {
      case matches('error.cancelled'):
        return translations.orderFailed.cancelled;
      case matches('error.order'):
        return translations.orderFailed.error;
      case matches('error.declined'):
        return translations.orderFailed.declined;
      case matches('error.pickupMissed'):
        return translations.orderFailed.pickupMissed;
    }
  };

  if (!deliveryOption) return null;
  const numberFormat = new Intl.NumberFormat(language, {
    style: 'currency',
    currency: deliveryOption.Currency,
  });

  return (
    <Fragment>
      <StepNavigation>
        <StepNavigationRow>
          <StepNavigationPrevious
            label={
              !state.customsRequired
                ? translations.paymentPage.previousStep
                : translations.paymentPage.previousStepCustoms
            }
            handleClick={
              state.customsRequired ? events.customs : events.packageContent
            }
          />
          <form
            onSubmit={(event) => {
              event.preventDefault();
              events.startOrder();
            }}
            tw="flex w-full ml-auto md:w-auto"
          >
            <span tw="ml-auto"></span>
            <StepNavigationNext
              type="submit"
              label={
                state.paymentType === 'card'
                  ? translations.paymentPage.nextStepByCard
                  : translations.paymentPage.nextStepByInvoice
              }
              labelExtra={`(${numberFormat.format(price?.priceincvat || 0)} ${
                deliveryOption.Currency
              })`}
              disabled={
                // @ts-ignore
                !PaymentGuards.validPayment(state)
              }
            />
          </form>
        </StepNavigationRow>
      </StepNavigation>

      <ModalSmall
        isOpen={matches('error')}
        onRequestClose={events.errorOk}
        shouldCloseOnOverlayClick={true}
        handleClose={events.errorOk}
      >
        <h3
          css={tw`block font-heading font-semibold text-2xl mb-4 leading-tight`}
        >
          {error()}
        </h3>
        <p tw="mb-2">
          {translations.support.needHelp}{' '}
          {translations.support.contactOurSupport}
        </p>
        <ul>
          <li tw="mb-1">
            <TextLink
              href={`tel:${getOneNumber(state.partnerInfo.webSupportNumber)}`}
            >
              {getOneNumber(state.partnerInfo.webSupportNumber)}
            </TextLink>
          </li>
          <li tw="mb-1">
            <TextLink href={`mailto:${translations.meta.email}`}>
              {translations.meta.email}
            </TextLink>
          </li>
        </ul>
      </ModalSmall>
      <Modal
        overlayClassName={modalOverlayLarge}
        className={modalContentPayment}
        isOpen={matches('cardPayment')}
        onRequestClose={events.cardCancelled}
      >
        {matches('cardPayment.init') && <p>Initializing card payment..</p>}
        {matches('cardPayment.start') && <p>Starting Nets payment..</p>}
        {matches('cardPayment.netsCheckout') && (
          <>
            {state.netsCk && state.netsPaymentId ? (
              <NetsCheckout
                events={events}
                matches={matches}
                state={state}
                checkoutKey={state.netsCk}
                paymentId={state.netsPaymentId}
                language={langToLocale(language)}
              />
            ) : (
              <p>Invalid netsCk or paymentId!</p>
            )}
          </>
        )}
        <div id="DlgNets"></div>
      </Modal>
    </Fragment>
  );
};
